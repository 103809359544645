<template>
  <div class="newSeeds">
    <div class="title">
      <div class="title-top">
        <el-button
          type="primary"
          size="small"
          icon="el-icon-circle-plus-outline"
          @click="addClick()"
          >新增设备</el-button
        >
        <div class="el-title-item">
          <el-form ref="form" :model="searchForm" label-width="80px">
            <el-form-item label="设备名称">
              <el-input
                v-model="searchForm.name"
                id="name"
                placeholder="请输入设备名称"
              ></el-input>
            </el-form-item>
            <!-- <el-form-item label="设备编号"> 
              <el-input
                v-model="searchForm.deviceCode"
                id="searchName"
                placeholder="请输入设备号"
              ></el-input>
            </el-form-item> -->
            <el-form-item style="margin-left: 2vh;">
              <el-button
                type="primary"
                icon="el-icon-search"
                size="small"
                @click="searchs()"
                >搜索</el-button
              >
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
    <el-table
      ref="multipleTable"
      :data="tableData"
      tooltip-effect="dark"
      style="width: 100%; height: 71vh; overflow-y: auto; margin-top: 2vh;"
      @selection-change="handleSelectionChange"
    >
      <el-table-column
        label="设备名称"
        prop="name"
        show-overflow-tooltip
        align="center"
      >
      </el-table-column>
      <el-table-column label="设备编号" prop="deviceNumber" align="center">
      </el-table-column>
      <!-- <el-table-column
        label="数据类型"
        prop="deviceType"
        show-overflow-tooltip
        align="center"
        :formatter="deviceTypeFormat"
      ></el-table-column> -->
      <el-table-column
        label="实时数据"
        prop="data"
        show-overflow-tooltip
        align="center"
      ></el-table-column>
      <el-table-column
        label="设备状态"
        prop="onLineState"
        show-overflow-tooltip
        align="center"
        :formatter="stateFormat"
      >
        <template slot-scope="scope">
          <span v-if="scope.row.onLineState==0">离线</span>
          <span v-else style="color:green">在线</span>
          <!-- <el-switch
            v-if="scope.row.onLineState == 0"
            :value="true"
            @change="changeSwitch(scope.row, scope.$index)"
          >
          </el-switch>
          <el-switch
            v-else-if="scope.row.onLineState == 1"
            :value="false"
            @change="changeSwitch(scope.row, scope.$index)"
          >
          </el-switch> -->
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button
            @click="updateClick(scope.row)"
            type="primary"
            size="small"
            icon="el-icon-edit"
          ></el-button>
          <el-button
            type="primary"
            size="small"
            icon="el-icon-delete"
            @click="deletes(scope.row.deviceNumber)"
          ></el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="block pagingBox">
      <el-pagination
        @current-change="changePage"
        @size-change="changeSize"
        :page-sizes="[10, 30, 50, 100]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="dataTotal"
      >
      </el-pagination>
    </div>

    <!-- 新增 -->
    <el-dialog
      title="新增设备"
      :visible.sync="addForm"
      width="31vw"
      class="addAlameBox"
    >
      <div slot="title" class="header-title" style="position: relative">
        <span class="title-name"></span>
        <span class="title-age">新增设备</span>
        <span
          style="
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 0px;
            font-size: 2.22vh;
          "
        >
        </span>
      </div>
      <div class="addAlaForm">
        <el-form ref="businessList" :model="form" label-width="10vw">
          <el-form-item label="设备名称" prop="deviceName">
            <el-input v-model="form.deviceName"></el-input>
          </el-form-item>
          <el-form-item label="设备编号" prop="deviceNumber">
            <el-input v-model="form.deviceNumber"></el-input>
          </el-form-item>
          <el-form-item label="设备密码">
            <el-input v-model="form.devicePassword" type="password"></el-input>
          </el-form-item>
          <el-form-item label="经度" prop="longitude">
            <el-input
              v-model.number="form.longitude"
              placeholder="点击地图获取经纬度"
            ></el-input>
          </el-form-item>
          <el-form-item label="纬度" prop="latitude">
            <el-input
              v-model.number="form.latitude"
              placeholder="点击地图获取经纬度"
            ></el-input>
          </el-form-item>
          <div class="echartMapContent" style="height: 30vh" id="plant_stand">
            <set-Map ref="childMap" @getDatas="getDatas"></set-Map>
          </div>
          <el-form-item size="large">
            <el-button class="cancelBtn" type="info" @click="addForm = false"
              >取消</el-button
            >
            <el-button class="saveBtn" type="primary" @click="addSubmit()"
              >确认</el-button
            >
          </el-form-item>
        </el-form>
      </div>
      <!-- <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="updateForm = false">保存</el-button>
    </span> -->
    </el-dialog>
    <!-- 编辑 -->
    <el-dialog
      title="编辑设备"
      :visible.sync="updateForm"
      width="35vw"
      class="addAlameBox"
    >
      <div slot="title" class="header-title" style="position: relative">
        <span class="title-name"></span>
        <span class="title-age">编辑设备</span>
        <span
          style="
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 0px;
            font-size: 2.22vh;
          "
        >
        </span>
      </div>
      <div class="addAlaForm">
        <el-form ref="businessList" :model="updateMsg" label-width="10vw" :rules="rules">
          <el-form-item label="设备名称">
            <el-input v-model="updateMsg.deviceName"></el-input>
          </el-form-item>
          <el-form-item label="设备编号">
            <el-input
              v-model="updateMsg.deviceNumber"
              :readonly="true"
            ></el-input>
          </el-form-item>
          <el-form-item label="经度">
            <el-input
              v-model="updateMsg.longitude"
              placeholder="点击地图获取经纬度"
            ></el-input>
          </el-form-item>
          <el-form-item label="纬度">
            <el-input
              v-model="updateMsg.latitude"
              placeholder="点击地图获取经纬度"
            ></el-input>
          </el-form-item>
          <div class="echartMapContent" style="height: 30vh;" id="plant_stand">
            <set-Map ref="childMap" @getDatas="getDatas1"></set-Map>
          </div>
          <el-form-item size="large">
            <el-button class="cancelBtn" type="info" @click="updateForm = false"
              >取消</el-button
            >
            <el-button class="saveBtn" type="primary" @click="upDateSubmit()"
              >确认</el-button
            >
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import setMap from "../../../components/setMap.vue";
export default {
  components: {
    setMap,
  },
  data() {
    return {
      pageSize: 10,
      pageNow: 1,
      dataTotal: 1,
      navList: [
        { id: "1", groupName: "二氧化碳检测设备" },
        { id: "2", groupName: "光照" },
        { id: "3", groupName: "气流检测设备" },
        { id: "4", groupName: "土壤状态检测" },
        { id: "5", groupName: "温湿度检测" },
        { id: "6", groupName: "墒情监测设备" },
        { id: "7", groupName: "温度" },
        { id: "8", groupName: "湿度" },
        { id: "9", groupName: "氨气" },
        { id: "10", groupName: "继电器" },
      ],
      rules: {
        deviceName: [{ required: true, message: "设备名称不可为空", trigger: "change" }],
        deviceNumber: [{ required: true, message: "设备编号不可为空", trigger: "change" }],
        longitude: [
          { required: true, message: "经度不可为空", trigger: "change" },
          { type: 'number', message: "不可输入数字以外的字符", trigger: "blur" }
        ],
        latitude: [
          { required: true, message: "纬度不可为空", trigger: "change" },
          { type: 'number', message: "不可输入数字以外的字符", trigger: "blur" }
        ]
      },
      popupDatas: null,
      markerDatas: null,
      tableData: [],
      nowIndex: 0,
      updateForm: false,
      addForm: false,
      checkInfo: false,

      dataTotal: 0,
      multipleSelection: [],
      value1: [new Date(2016, 9, 10, 8, 40), new Date(2016, 9, 10, 9, 40)],
      searchForm: {
        name: "",
        deviceCode: "",
      },
      value2: "",
      form: {
        deviceNumber: "",
        deviceName: "",
        deviceType: 10,
        longitude: 119,
        latitude: 39,
        devicePassword: "",
      },
      updateMsg: {
        deviceNumber: "",
        deviceName: "",
        deviceType: 10,
        longitude: 119,
        latitude: 39,
        devicePassword: "",
      },
      devicesTip:'',
      ownPassWord:'',
    };
  },
  mounted() {},
  methods: {
    // 根据设备号获取密码
    async getPassWord(){
      const {data:{data}} = await this.$get('/deviceManageTwo/getPassword',{device:this.devicesTip}) 
      this.ownPassWord = data.devicePassword
    },
    //控制
    async changeSwitch(row) {
      this.devicesTip = row.deviceNumber
      this.getPassWord()
      const { data } = await this.$post("/device/sendCommand", {
        deviceType: "2",
        deviceNumber: row.deviceNumber,
        command: "201",
        value: row.sendCode,
        devicePassword:this.ownPassWord ,
      });
      if (data.state == "success") {
        this.$message.success("操作成功");
        this.getList();
      } else {
        this.$message.error(data.msg);
        this.getList();
      }
    },

    clickMap(e) {
      let m = e.latlng;
      this.form.longitude = m.lng;
      this.form.latitude = m.lat;
      this.updateMsg.longitude = m.lng;
      this.updateMsg.latitude = m.lat;
    },
    // 更换每页数据
    changeSize(size) {
      this.pageSize = size;
      this.pageNow = 1;
      this.getList();
    },
    // 更换页码
    changePage(newpage) {
      this.pageNow = newpage;
      this.getList();
    },
    //   获取列表
    async getList() {
      const { data } = await this.$get("/deviceManageTwo/getDeviceLists", {
        page: this.pageNow,
        limit: this.pageSize,
        name: this.searchForm.name,
        deviceCode: this.searchForm.deviceCode,
        groupId: "2",
        type: 10,
      });
      var datas = data.datas;

      this.tableData = datas;
      this.dataTotal = data.count;
    },
    // 获取地图组件数据
    getDatas(e) {
      this.form.longitude = e.split(",")[0];
      this.form.latitude = e.split(",")[1];
      this.ruleForm.longitude = e.split(",")[0];
      this.ruleForm.latitude = e.split(",")[1];
      // this.$forceUpdate();
    },
    getDatas1(e) {
      this.updateMsg.longitude = e.split(",")[0];
      this.updateMsg.latitude = e.split(",")[1];
      this.ruleForm.longitude = e.split(",")[0];
      this.ruleForm.latitude = e.split(",")[1];
      // this.$forceUpdate();
    },
    // 删除行
    deletes(deviceNumber) {
      // 弹出提示是否删除
      this.$confirm("此操作将永久删除该记录, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$get("/device/delDeviceUser", {
            deviceNumber: deviceNumber,
            groupId: 2,
            type: 10
          }).then((res) => {
            if (res.data.state == "success") {
              this.$message.success("删除成功");
              this.getList();
            } else {
              this.$message.warning("删除失败");
            }
          });
        })
        .catch(() => {
          this.$message("取消删除");
        });
    },
    stateFormat(row) {
      if (row.onLineState == "1") {
        return <p style="color:green">在线</p>;
      } else {
        return <p style="color:red">离线</p>;
      }
    },
    deviceTypeFormat(row) {
      if (row.deviceType == 1) {
        return <p>二氧化碳检测设备</p>;
      } else if (row.deviceType == 2) {
        return <p>光照</p>;
      } else if (row.deviceType == 3) {
        return <p>气流检测设备</p>;
      } else if (row.deviceType == 4) {
        return <p>土壤状态检测</p>;
      } else if (row.deviceType == 5) {
        return <p>温湿度检测</p>;
      } else if (row.deviceType == 6) {
        return <p>墒情监测设备</p>;
      } else if (row.deviceType == 7) {
        return <p>温度</p>;
      } else if (row.deviceType == 8) {
        return <p>湿度</p>;
      } else if (row.deviceType == 9) {
        return <p>氨气</p>;
      } else if (row.deviceType == 10) {
        return <p>继电器</p>;
      } else {
        return <p>未知设备类型</p>;
      }
    },
    toggleSelection(rows) {
      if (rows) {
        rows.forEach((row) => {
          this.$refs.multipleTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    addSubmit() {
      if (!this.form.deviceName) {
        this.$message.info("请输入设备名称");
        return false;
      }
      if (!this.form.deviceNumber) {
        this.$message.info("请输入设备编号");
        return false;
      }
      if(!this.form.longitude){
        this.$message.info("经度不能为空");
        return false;
      } else if(this.form.longitude && isNaN(this.form.longitude)){
        this.$message.info("经度必须为数字");
        return false;
      }
      if(!this.form.latitude){
        this.$message.info("纬度不能为空");
        return false;
      } else if(this.form.latitude && isNaN(this.form.latitude)){
        this.$message.info("纬度必须为数字");
        return false;
      }
      // if (!this.form.longitude || !this.form.latitude) {
      //   this.$message.info("请输选择地图点位");
      //   return false;
      // }
      //var 大田种植 groupId = 1,温室大棚 groupId = 2,养殖物联 groupId = 3,高原特色 groupId = 4
      this.$post("/device/addUserDevice", {
        deviceNumber: this.form.deviceNumber,
        deviceName: this.form.deviceName,
        deviceType: this.form.deviceType,
        longitude: this.form.longitude,
        latitude: this.form.latitude,
        devicePassword: this.form.devicePassword,
        groupId: "2",
      }).then((res) => {
        if (res.data.state == "success") {
          this.$message.success("添加成功");
          this.addForm = false;
          this.getList();
        } else {
          this.$message.error(res.data.msg);
          this.addForm = false;
        }
      });
    },
    upDateSubmit() {
      if (!this.updateMsg.deviceName) {
        this.$message.info("请输入设备名称");
        return false;
      }
      if (!this.updateMsg.deviceNumber) {
        this.$message.info("请输入设备编号");
        return false;
      }
      if(!this.updateMsg.longitude){
        this.$message.info("经度不能为空");
        return false;
      } else if(this.updateMsg.longitude && isNaN(this.updateMsg.longitude)){
        this.$message.info("经度必须为数字");
        return false;
      }
      if(!this.updateMsg.latitude){
        this.$message.info("纬度不能为空");
        return false;
      } else if(this.updateMsg.latitude && isNaN(this.updateMsg.latitude)){
        this.$message.info("纬度必须为数字");
        return false;
      }
      // if (!this.updateMsg.longitude || !this.updateMsg.latitude) {
      //   this.$message.info("请输选择地图点位");
      //   return false;
      // }
    
      this.$post("/device/upDeviceInfo", {
        deviceNumber: this.updateMsg.deviceNumber,
        deviceName: this.updateMsg.deviceName,
        deviceType: 10,
        longitude: this.updateMsg.longitude,
        latitude: this.updateMsg.latitude,
        devicePassword: this.updateMsg.devicePassword,
        groupId: "2",
      }).then((res) => {
        if (res.data.state == "success") {
          this.$message.success("编辑成功");
          this.updateForm = false;
          this.getList();
        } else {
          this.$message.error(res.data.msg);
          this.updateForm = false;
        }
      });
    },
    addClick() {
      this.form.deviceNumber = "";
      this.form.deviceName = "";
      this.form.deviceType = 10;
      this.form.longitude = "";
      this.form.latitude = "";
      this.form.devicePassword = "";
      this.addForm = true;
      setTimeout(() => {
          this.$refs.childMap.setMap1(117.8627, 36.495014);
      }, 200)
    },
    updateClick(row) {
      setTimeout(() => {
        this.$refs.childMap.setMap1(row.longitude, row.latitude);
      }, 200);
      this.updateForm = true;
      this.updateMsg.deviceNumber = row.deviceNumber;
      this.updateMsg.deviceName = row.name;
      if (row.deviceType == "1") {
        row.deviceType = "二氧化碳检测设备";
      } else if (row.deviceType == 2) {
        row.deviceType = "光照";
      } else if (row.deviceType == 3) {
        row.deviceType = "气流检测设备";
      } else if (row.deviceType == 4) {
        row.deviceType = "土壤状态检测";
      } else if (row.deviceType == 5) {
        row.deviceType = "温湿度检测";
      } else if (row.deviceType == 6) {
        row.deviceType = "墒情监测设备";
      } else if (row.deviceType == 7) {
        row.deviceType = "温度";
      } else if (row.deviceType == 8) {
        row.deviceType = "湿度";
      } else if (row.deviceType == 9) {
        row.deviceType = "氨气";
      } else if (row.deviceType == 10) {
        row.deviceType = "继电器";
      }

      this.updateMsg.deviceType = row.deviceType;
      this.updateMsg.longitude = row.longitude;
      this.updateMsg.latitude = row.latitude;
      this.updateMsg.devicePassword = row.devicePassword;
    },
    checkBtn(param1, param2) {
      this.checkInfo = true;
    },
    // 搜索
    searchs() {
      this.getList();
    },
    reset() {
      this.searchForm.name = "";
      this.searchForm.deviceCode = "";
    },
    handleEdit() {},
    handleDelete() {},
  },
  created() {
    this.getList();
  },
};
</script>

<style scoped lang="less">
@import "../../../style/backTable.less";
</style>
  <style>
#plant_stand #map {
  height: 200px !important;
}
</style>
<style scoped>
    .addMap .mapBox {
        width: 100%;
        height: 29vh;
        margin-bottom: 2vh;
        border-radius: 10px;
    }
</style>